.app {
    position: relative;
}
.vote {
    position: relative;
    font-weight: 500;
    color: #9b9797;
}
.vote:hover{
    color: rgb(29, 100, 250);
}
.analytics {
    position: relative;
    font-weight: 500;
    color: #9b9797;
}
.analytics:hover{
    color: rgb(29, 100, 250);
}
.app-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    color: #f1f1f1;
}
.company {
    position: relative;
    color: #f1f1f1;
}

.company-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.nav-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.nav-dropdown-child {
    width: 247px;
    position: relative;
    height: 1px;
    object-fit: contain;
}
.trendswap-child {
    width: 48px;
    position: relative;
    border-radius: 12px;
    background: linear-gradient(226.41deg, #2299b7, #0a0b2a);
    height: 48px;
}
.available-on-ios {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    color: #9b9797;
}
.download-trendswap-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}
.rectangle-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #f1f1f1;
}
.mdigithub-icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.mdigithub-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 21px;
}
.nav-dropdown {
    position: fixed;
    border-radius: 16px;
    background-color: #131313;
    border: 1px solid #313131;
    box-sizing: border-box;
    top: 100%;
    left: 10%;
    width: 295px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: 20px;
    text-align: left;
    font-size: 14px;
    color: #9b9797;
    font-family: 'DM Sans';
    height: 500px;
    overflow-y:scroll;
    overflow-x: hidden;
    scrollbar-width:thin;
    scrollbar-color: rgba(255, 255, 255, 0.07) transparent;
}
@media screen and (max-width: 500px) {
    .nav-dropdown {
        height: 257px;

    }
    
}