* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

a {
  text-decoration: none;
}
.app-container {
  background: linear-gradient(180deg, #094c82 0%, #0c101c 58.5%);
  min-height: 100vh;
  :root {
    --fontcolor: #3498db;
  }
}
.glassmorphism-bg-icon {
  position: absolute;
  top: calc(50% - 82.5px);
  border-radius: 16px;
  object-fit: cover;
  opacity: 0.38;
}
.box {
  box-shadow: 0px 0px 20px red;
}
/* =========== Main Home Page =========== */

.yGBCj {
  background: rgb(48, 50, 53);
  display: grid;
  width: 100%;
  grid-auto-rows: auto;
  row-gap: 0px;
  position: absolute;
  top: 0;
}
._883456mh {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 24px;
  width: 100%;
  height: 72px;
  z-index: 3;
  position: sticky !important;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(4px);
  background: none;
  transition: background-color 0.25s ease 0s;
}
._883456imh {
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.dark a {
  color: rgba(255, 255, 255, 1);
}
.fmUYaN {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}
.bYSOwc {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: auto;
}
.jXvTxb {
  color: #fff;
}
.jXvTxb:hover {
  color: #fff;
}
.dx {
  font-family: "DM Sans";
  background: linear-gradient(90deg, #0484c1 23.5%, #07379f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-size: 56px;
}

.icon {
  width: 84px;
  position: relative;
  height: 84px;
  overflow: hidden;
  flex-shrink: 0;
}
.secure-trading {
  position: relative;
  font-size: 24px;
  color: #fff;
}
.trade-with-confidence {
  position: relative;
  font-size: 14px;
  color: #c4c4c4;
  display: inline-block;
}
.secure-trading-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 8px;
}
.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  gap: 24px;
  text-align: left;
  font-size: 36px;
  color: #fff;
  font-family: "DM Sans";
}
._88306012 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  box-sizing: border-box;
  gap: 12px;
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-family: "DM Sans";
}

.implement-the-stable {
  position: relative;
  font-size: 36px;
  display: inline-block;
  font-family: "DM Sans";
  color: #fff;
  text-align: center;
}
.trenddx-logo {
  height: 40px;
  width: auto;
}
.trenddx-is-your {
  position: relative;
  font-weight: 200;
  font-size: 24px;
  line-height: 1;
  padding: 10px;
  max-width: 90%;
  font-family: "DM Sans";
  color: #fff;
  text-align: center;
  display: inline-block;
  line-height: 28px;
}

.trenddx {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  font-size: 64px;
  font-family: "DM Sans";
  text-align: left;
  color: #f1f1f1;
}
.dx sup {
  vertical-align: super;
  font-size: 56px;
}

.protocol {
  color: rgba(64, 252, 254, 0.83);
  text-align: center;
  font-size: 64px;
  font-weight: bold;
}
.start-trading-today {
  color: rgba(64, 252, 254, 0.83);
  text-align: center;
  font-size: 56px;
  font-weight: bold;
}
.experience-the-power {
  font-family: "DM Sans";
  background: linear-gradient(90deg, #b3d9ff, #647c93);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
._883060122 {
  padding: 4px 25px;
  position: relative;
  border-radius: 7.73px;
  background: linear-gradient(
    180deg,
    rgba(8, 11, 19, 0.3),
    rgba(0, 50, 126, 0.3) 57.5%
  );
  border: 1px solid #005cb0;
  box-sizing: border-box;
}
.overlay-text {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  max-width: 90%;
  font-family: "DM Sans";
}
.social-icon-footer {
  justify-content: end;
}
.experience-the-power {
  font-size: 20px;
}

@media screen and (max-width: 500px) {
  .trenddx-logo {
    height: 24px;
  }
  .trenddx {
    flex-direction: column;
    align-items: center;
    line-height: 1;
    font-size: 32px;
  }
  .dx {
    font-size: 24px;
  }
  .protocol {
    font-size: 32px;
  }
  .overlay-text {
    font-size: 20px;
    line-height: 1;
  }
  .trenddx-is-your {
    font-size: 16px;
    line-height: 1;
  }
  ._88306012 {
    padding: 0px 10px;
  }
  .secure-trading {
    font-size: 16px;
  }

  .start-trading-today {
    font-size: 32px;
    padding: 0px 10px;
  }
  .experience-the-power {
    font-size: 12px;
    padding: 0px 10px;
  }
  .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }  
  .social-icon-footer {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .bYSOwc {
    display: none;
  }
  .MobileMenu__Link {
    padding: 20px auto;
    color: #40fcfe !important;
  }
}
@media (min-width: 768px) {
  .eFGaxv {
    display: none;
  }
}

.eFGaxv {
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

@media (max-width: 960px) {
  .bpZKHt {
    text-align: center;
    text-decoration: none;
  }
}

.bpZKHt {
  padding: 8px 0.85rem;
  text-decoration: none;
  border-radius: 12px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      95.5deg,
      rgba(15, 53, 255, 0.3) 12.82%,
      rgba(255, 72, 181, 0.3) 41.96%,
      rgba(15, 53, 255, 0.08) 75.06%,
      rgba(15, 53, 255, 0.11) 107.66%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: rgb(53, 55, 58) 1px 1000px 1px inset;
  opacity: 0.9;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  width: fit-content !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  height: fit-content !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.rectangle-div {
  width: 120px;
  position: relative;
  height: 38px;
}
.launch-app {
  position: relative;
}
.ecosystem {
  padding: 8px 16px;
}
.launch-app-parent {
  position: relative;
  border-radius: 8px;
  background: rgba(64, 252, 254, 1);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  color: #1b1c1e;
  font-family: "DM Sans";
}
/* ======================== Navigation CSS ===================== */
.header_wrapper {
  background: rgba(0, 0, 0, 0.38);
  backdrop-filter: blur(10px);
}
.icons {
  color: rgba(155, 151, 151, 1) !important ;
  height: 25px;
  width: 25px;
}
.icons:hover {
  color: white !important;
}

.me-auto {
  margin-right: 20px !important;
}
.nav-link {
  color: rgba(155, 151, 151, 1) !important ;
  border-radius: 8px !important;
}
.nav-link:hover {
  color: white !important;
  border-radius: 8px !important;
  /* background-color: rgba(11, 59, 100, 1); */
}
/* .search_input_div {
  background-color: rgba(11, 59, 100, 1);
  border-radius: 40px;
  width: 450px;
  min-height: 36px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}
.search_result {
  background-color: #1b1b1b;
  width: 450px;
  margin-top: 10px;
  position: absolute;
  min-height: auto;
  max-height: 300px;
  overflow-y: scroll;
  top: 40px;
  border: 1px solid #585858;
  border-radius: 10px;
}
#searchbar::placeholder {
  font-size: 12px;
  color: rgba(155, 151, 151, 1);
}
.search_result::-webkit-scrollbar {
  display: none;
}
.fa-magnifying-glass {
  color: white;
  margin: 8px;
  margin-left: -25px;
} */
.btnGroupDrop1 {
  border-radius: 20px !important;
  background-color: rgba(10, 30, 39, 1) !important;
  border: transparent !important;
  padding: 6px 12px;
}
.connect1 {
  position: relative;
  font-size: 16px;
  background: linear-gradient(
    253.8deg,
    #fef1bb,
    #b9a860 22.5%,
    #e1d088 47.5%,
    #c9b667 72%,
    #fef1bb
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.connect-button1 {
  position: relative;
  background: rgba(64, 252, 254, 0.3);

  padding: 8px 16px;
  border-radius: 99px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "DM Sans";
}
.currency-input {
  border-radius: 10px !important;
  width: 84px !important;
  outline: transparent !important;
  padding: 5px 5px;
  border: none;
  background-color: transparent;
  position: relative;
  font-size: 24px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #f1f1f1;
}
.currency-input-new {
  border-radius: 10px !important;
  position: relative;
  width: 100%;
  outline: transparent !important;
  border: none;
  background-color: transparent;
  padding-left: 2px;
  font-size: 20px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #f1f1f1;
  filter: none;
  opacity: 1;
  transition: opacity 250ms ease-in-out 0s;
  text-align: left;
  font-size: 36px;
  font-weight: 485;
  max-height: 44px;
}

.currency-input-new::placeholder {
  font-size: 36px;
}

.arrow-container {
  position: relative;
  top: 52%;
  transform: translateY(-52%);
}
.arrow-container img {
  margin-top: -2px;
}

.swap-anytime {
  margin: 0;
}
.swap-anytime-anywhere-container {
  position: relative;
  font-size: 46px;
  line-height: 100%;
  font-weight: 500;
  font-family: "DM Sans";
  color: #f1f1f1;
  text-align: center;
}

.max-parent-swap {
  top: 5%;
}
.we {
  position: relative;
  border-radius: 99px;
  background: rgba(5, 44, 76, 1);
  box-sizing: border-box;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: "DM Sans";
}
.ws {
  position: relative;
  border-radius: 99px;
  background-color: rgba(35, 105, 116, 1);
  box-sizing: border-box;
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: "DM Sans";
}
.connect-button {
  position: relative;
  border-radius: 8px;
  background-color: rgba(64, 252, 254, 0.3);
  text-align: center;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  color: rgba(64, 252, 254, 1);
  font-family: "DM Sans";
}
.enterAmountAB {
  position: relative;
  border-radius: 8px;
  text-align: center;
  padding: 10px 16px;
  background: linear-gradient(rgba(2, 40, 69, 0.19), rgba(2, 40, 69, 0.19)),
    linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.25),
      rgba(217, 217, 217, 0.25)
    );
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 700;
  color: rgba(155, 151, 151, 1);
  font-family: "DM Sans";
}
.connect-button-green {
  position: relative;
  border-radius: 8px;
  background: rgba(64, 252, 254, 0.3);
  border: 1px solid #008000;
  padding: 8px 16px;
  color: #3da03d;
  font-family: "DM Sans";
}
.connect-button-blue {
  position: relative;
  border-radius: 8px;
  background: rgba(64, 252, 254, 0.3);
  border: 1px solid #1d64fa;
  padding: 8px 16px;
  color: #f1f1f1;
  font-family: "DM Sans";
}
.connect-wallet {
  position: relative;
  font-size: 20px;
  font-family: "DM Sans";
  color: #424242;
  text-align: left;
}
.connect-wallet-input {
  width: 100%;
  border: transparent;
  outline: none;
  position: relative;
  border-radius: 10px;
  background-color: #1f3c4a;
  box-sizing: border-box;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-family: "DM Sans";
  font-weight: 500;
}
/* ============== Swap page ================ */
#swapbottom {
  margin-top: -35px;
}
.connectWallet {
  border-radius: 10px;
  background-image: linear-gradient(
    to right,
    rgba(254, 241, 187, 1),
    rgba(185, 168, 96, 1),
    rgba(225, 208, 136, 1),
    rgba(201, 182, 103, 1),
    rgba(254, 241, 187, 1)
  );
  font-size: 30px;
  border: transparent;
  outline: none;
}
.connectWallet a {
  text-decoration: none;
  padding: 5px 20;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.05);
  overflow: auto;
  padding: 10px;
}
.morphism {
  background: rgba(66, 90, 112, 0.3);
  box-shadow: 0px 21.36px 26.7px 0px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(10px);
}
/* ============= Explore Page =============== */
Button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
}
.tab-button {
  background-color: rgba(56, 122, 223, 0.5) !important;
  border: transparent;
  border-radius: 10px;
  outline: none;
  color: #fff;
  padding: 6px 6px;
  border-radius: 99px;
}

.hrxVYA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(56, 122, 223, 0.5);
  border-color: rgba(56, 122, 223);
  border-radius: 20px;
}
.tokenimg {
  height: 24px;
  width: 24px;
}
th,
tr,
td {
  border: 0 !important;
}
.badges {
  background-color: #1b1b1b;
  color: #9b9797;
}
.nav {
  background-color: transparent;
}

.tabcolor {
  color: rgba(155, 151, 151, 1) !important;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 47px;
  letter-spacing: 0px;
  text-align: left;
  background-color: transparent !important;
}

.tabcolor:hover {
  color: white !important;
}

.thead-background {
  background: transparent;
  color: rgba(155, 151, 151, 1) !important;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.d {
  position: relative;
  font-weight: 500;
}
.frame-parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0px 6.6px;
  text-align: left;
  font-size: 18.87px;
  color: #9b9797;
  font-family: "DM Sans";
}

/* ==================== Analytics page ================ */
.tablestruct {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #01336080;
}
.trendswap-analytics {
  position: relative;
  font-size: 14px;
}
.tswap {
  font-size: 24px;
  color: rgba(241, 241, 241, 1);
}
.liquidity-hub-wrapper {
  border-radius: 8px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  cursor: pointer;
}
.total {
  position: relative;
  font-size: 14px;
}
.total-wrapper {
  background-color: rgba(29, 100, 250, 0.3);
  outline: 1.5px solid #1d64fa;
}
.total-wrapper-total {
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  color: #9b9797;
}
.trendswap-analytics-parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 35px;
  text-align: left;
  font-size: 24px;
  color: #c7cad9;
  font-family: "DM Sans";
}

.overview {
  position: relative;
  font-size: 16px;
}

.tokens-wrapper {
  border-radius: 8px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.total-group {
  border-radius: 8px;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px 10px;
}
.ticker {
  font-weight: 600;
  font-size: 20px;
  font-family: "DM Sans";
  color: rgb(155, 155, 155);
  color: #f1f1f1;
  letter-spacing: -0.01em;
}
.ticker_value {
  color: rgb(155, 155, 155);
  font-family: "DM Sans";
  color: #9b9797;
}
.frame-parent1 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  font-size: 20px;
  color: #9b9797;
  font-family: "DM Sans";
}

.h-most-volume {
  position: relative;
}

.topMoversContent {
  margin-top: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 16px;
  width: 100%;
}
.topMoversContent > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.topMoverItem {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 16px;
  width: auto;
}
.jss66 {
  width: 28px;
  height: 28px;
  border-radius: 28px;
}
.currencyLogo {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.jss67 {
  margin-left: 8px;
}
.text-bold {
  font-weight: 700;
}

.small,
small {
  font-size: 14px;
  line-height: 1.57;
}
.topMoverText {
  align-items: center;
  border-radius: 10px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 8px;
  color: #0fc679;
  background-color: #0fc6794d;
  padding: 0 6px;
}
.cryptocurrency-coloreth-icon {
  width: 48px;
  position: relative;
  height: 48px;
}
.parent-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #f1f1f1;
}
.chart-btn-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #f1f1f1;
}
.h-most-volume-parent {
  width: 100%;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 28px 16px;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 16px;
  color: #696c80;
  font-family: "DM Sans";
}
.frame-parent button {
  outline: none;
  border: transparent;
}
.d {
  position: relative;
  font-weight: 500;
}

.d-wrapper {
  width: 100%;
  position: relative;
  border-radius: 99px;
  background-color: rgb(158 169 174 / 24%);
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9.435096740722656px;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #c7cad9;
  font-family: "DM Sans";
}

.w-wrapper {
  position: relative;
  border-radius: 99px;
  background-color: transparent;
  height: 32px;
  min-width: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9.435096740722656px;
  box-sizing: border-box;

  font-size: 12px;
  color: #c7cad9;
  font-family: "DM Sans";
}

.parent1 {
  background-color: rgba(214, 95, 82, 0.25);
  border-radius: 10px;
  color: rgba(214, 95, 82, 1);
}
.parent2 {
  background-color: rgba(34, 157, 100, 0.25);
  border-radius: 10px;
  color: #0fc679;
}
.m {
  font-size: 20px;
  color: rgba(241, 241, 241, 1);
}
.b {
  position: relative;
  font-size: 12px;
}
.b:hover {
  /* border-bottom: 1px solid #0fc679; */
}
.h-volume-118403423497-parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 18px;
  text-align: left;
  color: #c7cad9;
  font-family: "DM Sans";
}
.top-tokens {
  position: relative;
}
.top-tokens:active1 {
  color: #1d64fa;
}
.iconamoonarrow-up-2-thin {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.see-all-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 4px;
}
.top-tokens-parent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px 1511px;
  text-align: left;
  font-size: 24px;
  color: #f1f1f1;
  font-family: "DM Sans";
}
.top-tokens {
  position: relative;
  font-size: 16px;
  font-family: "DM Sans";
  color: #f1f1f1;
  text-align: left;
}
.see-all {
  position: relative;
  font-size: 16px;
  font-family: "DM Sans";
  color: #f1f1f1;
  text-align: left;
}

/* ============ Liquidity page ================== */

.liquidity-parent {
  padding: 10px 15px;
  width: 289px;
  color: #9b9797;
  border-radius: 10px;
  margin: 10px 0px;
}
.liquiditytext {
  font-size: 14px;
}
.fs {
  font-size: 24px;
  color: rgba(241, 241, 241, 1);
}
.days {
  background-color: #0f2733;
  padding: 5px 8px;
  font-size: 14px;
  border-radius: 99px;
}
.p {
  padding: 0 !important;
}
.volume {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #9b9797;
}

.since-2023-09-18-parent {
  position: relative;
  border-radius: 99px;
  background-color: #0f2733;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 16px;
  color: #9b9797;
  font-family: "DM Sans";
}
.div {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #f1f1f1;
  text-align: center;
}

/* =============== Pools ================= */
.liquidity-provider-rewards {
  position: relative;
  font-size: 24px;
  font-family: "DM Sans";
  color: #f1f1f1;
  text-align: center;
}
.liquidity-providers-earn {
  position: relative;
  font-size: 14px;
  font-family: "DM Sans";
  color: #9b9797;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.your-liquidity {
  position: relative;
  font-size: 20px;
  font-family: "DM Sans";
  color: #f1f1f1;
  text-align: left;
  margin: 0;
}
.create-a-pair,
.add-liquidity {
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  text-align: left;
  font-family: "DM Sans";
  font-size: 14px;
}
.add-liquidity {
  background: rgba(64, 252, 254, 0.3);

  color: rgba(64, 252, 254, 1);
}
.create-a-pair {
  border: 1px solid #f1f1f1;
  color: #f1f1f1;
  cursor: pointer;
}
.no-liquidity-found-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: 1px solid rgba(155, 151, 151, 1);
  border-radius: 8px;
}
.no-liquidity-found {
  color: rgba(155, 151, 151, 1);
  margin: 0;
}
.dont-see-a {
  color: #9b9797;
  font-size: 14px;
}
.import-it {
  color: rgba(64, 252, 254, 1);

  font-size: 14px;
}

/* ========== create pair ========== */
.you-are-the {
  position: relative;
  font-size: 14px;
  font-family: "DM Sans";
  color: #f1f1f1;
  text-align: center;
  margin-bottom: 0;
}
.the-ratio-of {
  position: relative;
  margin: 0;
  font-size: 10px;
  font-family: "DM Sans";
  color: #9b9797;
  text-align: center;
}
.plus {
  background-color: rgba(255, 255, 255, 0.05);
  z-index: 999;
  outline: none;
  border: transparent;
  border: 3px solid transparent;
  border-radius: 14px;
}
#swapbottom1 {
  margin-top: -50px;
}
.invalid-pair {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  color: rgba(155, 151, 151, 1);
}
.invalid-pair-text {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-family: "DM Sans";
  color: #9b9797;
  margin: 0;
  background-color: red;
}
.max-parent-create {
  left: 5%;
}
.import-pool-parent {
  padding: 6px 16px;
  border-radius: 6px;
}
.select-a-token {
  position: relative;
  font-size: 14px;
  font-family: "DM Sans";
  color: #fff;
  text-align: center;
}

/* ============Add liquidty====== */
.active1 {
  color: #fff;
}

/* ============== Swap new =============== */
.jRlUBU {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
  z-index: 1;
}
.khwJOa {
  position: relative;
  max-width: 450px;
  width: 100%;
  border-radius: 30px;
  padding: 1.5rem;
  z-index: 1;
  border: 0.1px solid rgb(19, 113, 115);

  background: linear-gradient(180deg, #032e50 0%, #101525 100%),
    linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.075) 0%,
      rgba(217, 217, 217, 0.075) 100%
    );
  box-shadow: 5.60733px 52.8691px 46.4607px rgba(0, 0, 0, 0.2);
}
.enpFfZ {
  position: relative;
}
.iUsjHb {
  border-radius: 8px;
}
.highlight:hover {
  background-color: rgba(15, 91, 154, 0.418);
}
.hhoFBL {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image {
  transition: filter 0.3s ease;
}
.rotated {
  transform: rotate(180deg);
}
/* ========== modal ========== */
.rounded-20 {
  border-radius: 20px !important;
}
.modal-content {
  position: absolute;
  border-radius: 20px !important;
  background-color: rgb(19, 19, 19);
  width: 400px !important;
  box-sizing: border-box;
}
.gnwYND {
  border-radius: 20px;
  overflow: hidden;
  flex: 1 1 0%;
  position: relative;
  border: 1px solid #585858;
}
.bFCsHr {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.hfXcaM {
  padding: 20px;
}
.kMkmMS {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 16px;
}
.bIFEzi {
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.cPCYrp {
  width: 100%;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: space-between;
}
.kdYshZ {
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 4px;
}
.dKubqp {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.hTynGq {
  background: rgb(27, 27, 27);
  position: relative;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.07);
  height: 40px;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  border-radius: 12px;
  appearance: none;
  font-weight: 485;
  font-size: 16px;
  transition: border 100ms ease 0s;
}
.hTynGqr {
  height: 40px;
  font-size: 14px;
  margin-left: 10px;
  background-color: transparent;
  outline: none;
  border: transparent;
  color: rgb(255, 255, 255);
}

.kjpUwf {
  display: flex;
  background: none;
  padding: 10px 8px;
  gap: 4px;
  border-radius: 12px;
  height: 40px;
  color: rgb(255, 255, 255);
  transition: background-color 250ms ease 0s, margin 250ms ease 0s;
}

.lmdAXV {
  line-height: 24px;
  font-weight: 535;
  text-align: center;
  border-radius: 16px;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.07);
  text-decoration: none;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.bbWEFp {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  font-size: inherit;
  color: white;
}
.fxVDAj {
  flex-wrap: wrap;
  margin: -4px;
}
.crYogb {
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 18px;
  display: flex;
  padding: 5px 12px 5px 6px;
  line-height: 0px;
  font-size: 16px;
  font-family: "DM Sans";
  color: #a1a1a1;
  -webkit-box-align: center;
  align-items: center;
}
.crYogb span {
  margin-left: 6px;
}
.cFvOnL {
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0px, 72px);
  gap: 16px;
  cursor: pointer;
  opacity: 1;
}
.cFvOnL:hover {
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
}
.eENUcJ {
  display: grid;
  grid-auto-rows: auto;
}
.css-1m0dqmt {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 485;
  font-size: 12px;
}
.jgbRhf {
  color: rgb(155, 155, 155);
  letter-spacing: -0.01em;
}
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.07) transparent;
  border-top: 1px solid #585858;
  max-height: 300px;
  overflow-y: scroll;
}
.dKubqptext {
  font-family: "DM Sans";
  color: #f1f1f1;
}

.customtab {
  color: white !important;
  background: rgba(56, 122, 223, 0.5) !important;
  font-weight: 800 !important;
}

.navlinks {
  background-color: transparent !important;
  color: #a1a1a1 !important;
}
.active {
  color: white !important;
}
.modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
}

.modal-content1 {
  background-color: rgba(27, 28, 30, 1);
  padding: 20px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "DM Sans";
  color: #f1f1f1;
  overflow: hidden;
}
.check-box-parent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.i-have-read {
  font-size: 12px;
  font-family: "DM Sans";
  color: #f1f1f1;
}

/* Modal styles */
.modal5 {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
}

.modal-content5 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
}

.close5 {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close5:hover,
.close5:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.prices-and-pool {
  position: relative;
}
.prices-and-pool-share-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 16px;
}
.eth-per-wbtc {
  position: relative;
  color: #9b9797;
}
._883756cp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
._883656cp {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}
._883556cp {
  align-self: stretch;
  border-radius: 20px;
  border: 0.5px solid #585858;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 6px;
}
._883456cp {
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 8px 0px 0px;
  font-size: 14px;
  color: #9b9797;
  font-family: "DM Sans";
}

._883856cp {
  position: relative;
  max-width: 510px;
  width: 100%;
}

.phstar-fill-icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.youll-earn-025 {
  color: #f1f1f1;
}
.by-adding-liquidity-container {
  flex: 1;
  position: relative;
  font-size: 14px;
}
.phstar-fill-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.approve-token {
  position: relative;
  font-weight: 500;
}

._883856pp {
  width: 100%;
  position: relative;
  border-radius: 16px;
  border: 1px solid #545454;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 5px;
  text-align: left;
  font-size: 16px;
  color: #f1f1f1;
  font-family: "DM Sans";
}
._883856ad {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.waiting-for-confirmation-parent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #f1f1f1;
  font-family: "DM Sans";
  padding-bottom: 20px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
._883856tt {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}
.modal-dialog {
  max-width: 400px !important;
  margin: 1.75rem auto;
}

.x905656cc48b31b9b6b0d298486901 {
  position: relative;
  font-weight: 500;
}
.x905656cc48b31b9b6b0d298486901-parent {
  width: 100%;
  position: relative;
  border-radius: 16px;
  border: 1px solid #545454;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  text-align: left;
  font-size: 16px;
  color: #f1f1f1;
  font-family: "DM Sans";
}

.token-name {
  position: relative;
  font-weight: 500;
}
.pharrow-down-icon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.pharrow-down-wrapper {
  border-radius: 12px;
  background-color: #2c2f36;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
}
.token-name-parent {
  position: relative;
  border-bottom: 1px solid #515151;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 0px 16px;
  text-align: left;
  font-size: 16px;
  color: #f1f1f1;
  font-family: "DM Sans";
  margin-top: 10px;
}

.found-by-address-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  font-size: 16px;
}
.add {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #40fcfe;
  text-align: left;
  cursor: pointer;
}

.select-a-list {
  position: relative;
  font-weight: 500;
}
.select-a-list-parent {
  width: 100%;
  position: relative;
  border-top: 1px solid #696969;
  box-sizing: border-box;
  height: 44.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #f1f1f1;
  font-family: "DM Sans";
}
.pool-found {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #fff;
  text-align: center;
}
.manage-this-pool {
  position: relative;
  font-size: 14px;
  font-family: "DM Sans";
  color: #40fcfe;
  text-align: center;
  cursor: pointer;
}
.manage-this-pool:hover {
  color: #40fcfe;
}

.account-analytics-and {
  position: relative;
}
.pharrow-down-icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.account-analytics-and-assured-parent {
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 1px solid #40fcfe;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 16px;
  color: #40fcfe;
  font-family: "DM Sans";
  cursor: pointer;
}

.rotatedarrow {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

._883060pi {
  height: 32px;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 32px;
  color: white;
}

._883060pit {
  position: relative;
  font-weight: 500;
}
._883060pa {
  position: relative;
  border-radius: 8px;
  background-color: rgba(64, 252, 254, 0.3);
  box-shadow: 0px 8px 21.8px rgba(51, 51, 51, 0.25);
  padding: 4px 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #40fcfe;
  font-family: "DM Sans";
}
.confirm-this-transaction {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #9b9797;
  text-align: left;
}

.vwKsb {
  width: 100%;
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: flex-start;
}
.eAjRWn {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.fzzMop {
  width: 100%;
}
.xsFBJ {
  width: 100%;
  height: 300px;
  padding: 1rem 2rem 1rem 1rem;
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  flex-direction: column;
}
.indocQ {
  width: 100%;
  border-radius: 16px;
  padding: 1rem;
  min-height: 332px;
}
.gvVAeR {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 220px;
}
.bfQFKp {
  justify-content: space-between;
}
.hzNkCY {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 4px;
}
.css-1s9bpqv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 16px;
}

.jHHQTI {
  padding: 4px;
  width: fit-content;
  font-size: 12px;
  border-radius: 50%;
  min-width: 36px;
  background-color: rgb(158 169 174 / 24%);
  color: rgb(195, 197, 203);
  padding: 7.435096740722656px;
  font-weight: 500;
  outline: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.iCIFLG {
  padding: 8px 16px;

  font-weight: 500;
  text-align: center;

  outline: none;
  border: 1px solid transparent;

  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.dollarValue {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 485;
  font-size: 16px;
  color: rgb(155, 155, 155);
  letter-spacing: -0.01em;
}
.balance {
  color: rgba(181, 181, 181, 1);
  margin-left: auto;
  font-size: 16px;
}

.enpFfZ {
  position: relative;
}
.max {
  background: rgb(64, 252, 254);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 16px;
  font-weight: 700;
  color: transparent;
}
.maxi{
  background: grey;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 16px;
  font-weight: 700;
  color: transparent;
}

.carderbg {
  background-color: rgba(66, 90, 112, 0.3);
}
.carder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goRgmL {
  background-color: rgba(238, 179, 23, 0.33);
  color: rgb(238, 179, 23);
  font-size: 10px;
}
.bIBvpU {
  background-color: rgb(27, 27, 27, 0.33);
  color: rgb(155, 155, 155);
  font-size: 10px;
}
.bncztk {
  padding: 6px 12px;

  border-radius: 16px;
}
.table-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.table-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .table-tab {
    flex-direction: column;
    align-items: flex-start;
  }
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  color: white;
}

.dropdown-toggle {
  background-color: rgba(23, 74, 186, 0.4);
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 16px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  text-align: center;

  color: white;
  background-color: rgba(20, 82, 143, 0.12);
  min-width: 50px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

  right: 0px;
  bottom: calc(100% + 10px);
  backdrop-filter: blur(10px);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  color: white;
}

.dropdown-item:hover {
  background-color: #bdc6d05a;
  color: white;
}

.pageCount {
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 3px;
}
.sorted-column {
  color: rgb(64, 252, 254);
}

.pairs-container .pagination-icon {
  display: inline-block;
  transition: transform 0.2s ease-in-out;
}

.pairs-container:hover .pagination-icon {
  transform: translateX(3px);
}

/* Container for the entire search bar component */
.search-bar-container {
  position: relative; 
  width: 100%; 
  margin-right: 10px;
}
.search-bar {
  display: flex;
  align-items: center;
  background-color: rgba(11, 59, 100, 1);
  border-top: none;
  border: 1px solid #464646;
  border-radius: 99px;
  padding: 2px 8px;
  width: 100%; 
}

.search-dropdown::-webkit-scrollbar {
  display: none;
}
.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.search-input {
  border: none;
  outline: none;
  background-color: rgba(11, 59, 100, 1);
  color: white;
  flex-grow: 1;
  padding: 4px 8px;
}
.search-input::placeholder{
  font-size: 14px;
  color: rgba(155, 151, 151, 1);
}
.key-shortcut {
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 12px;
  margin-left: 8px;
}
.search-dropdown {
  position: absolute;
  top: 100%; 
  left: 0;
  min-height: auto;
  max-height: 300px;
  overflow-x: hidden;
  width: 100%;
  background-color:  rgba(11, 59, 100, 1);
  border:1px solid #464646;;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
}
.search-dropdown p {
  padding: 8px;
  margin: 0;
  border-bottom: 1px solid #eee;
}
.search-dropdown p:last-child {
  border-bottom: none; 
}
.create-pair-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: flex; /* Set the container height to the viewport height */
  margin: 0; /* Remove default margin */
}
.create-pair-button {
  background-color: rgba(64, 252, 254, 0.3);
  color: #fff;
  border: 2px solid rgba(64, 252, 254, 0.3);
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 1rem; /* Set a base font size */
}
.create-pair-button:hover {
  background-color: #0088cc;
  border-color: #005f99;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .create-pair-button {
      font-size: 0.875rem; /* Reduce font size on smaller screens */
      padding: 8px 16px; /* Reduce padding on smaller screens */
  }
}

/* Overlay background to dim the screen behind the dialog */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
}

/* Dialog box styling */
.dialog-box {
  max-width: 500px;
  padding :50px;
  height: 420px;
  border-radius: 40px;
 
  background:#1B1C1E;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dialog-box h3 {
  margin-top: 0;
  font-size: 1.25rem;
}

.dialog-box p {
  font-size: 1rem;
  margin-bottom: 20px;
}

/* Button container */
.dialog-actions {
  display: flex;
  justify-content: space-around;
}

/* Confirm and Cancel button styles */
.dialog-confirm, .dialog-cancel {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dialog-confirm {
  background-color: #4caf50;
  color: #fff;
}

.dialog-cancel {
  background-color: #f44336;
  color: #fff;
}
.header-hover:hover {
  color: #1a73e8; /* Change to your preferred hover color */
  cursor: pointer;
}
.price-impact-text{
  color:#FFFFFF;
  font-family: "DM Sans";
  font-size: 10.62px;
  font-weight: 500;
  line-height: 16.3px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  }
  .header-hover {
   
    border-radius: 4px;}
.header-hover:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Change to desired hover color */
    color: #007bff; /* Optional: change text color */
}
.table-container {
  overflow-x: auto; /* Enables horizontal scrolling for smaller screens */
  margin-bottom: 1rem;
}

.liquidity-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem; /* Adjusted font size */
  text-align: center;
}

.liquidity-table th, .liquidity-table td {
  padding: 8px;
  border: 1px solid #ddd;
  color: #c4c4c4;
}

.liquidity-table th {
  color: rgba(64, 252, 254, 1);
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 1rem; */
  gap: 0.5rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: none;
  color: rgba(64, 252, 254, 1);

 
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
}

.pagination button:disabled {
 
  cursor: not-allowed;
}

.pagination span {
  font-size: 0.9rem;
  color: #333;
}
