.offcanvas {
    position: fixed;
    top: 0;
    right: -100%;
    margin: 5px;
    height: 100% !important;
    width: 300px !important;
    background-color: rgb(19, 19, 19) !important;
    transition: right 0.3s ease;
    overflow-y: auto;
    border-radius: 10px;
    overflow-y: auto;
    border: 0.7px solid #464646;
    margin-bottom: 20px;
    color: white !important;
  }
  
  .offcanvas-button {
    background-color: rgb(19, 19, 19) !important;
    border: transparent;
    outline: none;
  }
  .option-tab:hover{
    background-color: rgba(255, 255, 255, 0.07) !important;
    border-radius: 10px;
  }
  .option-tabpara{
    padding: 0;
    margin: 0;
  }
  
  .canvas-setting {
    height: 24px;
    width: 24px;
  }
  
  .offcanvas.show {
    right: 0;
  }
  
  .offcanvas p {
    padding: 20px;
    margin: 0;
  }