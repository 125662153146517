.trendswapx {
    position: relative;
    font-weight: 500;
    color: #fff;
}
  .when-available-aggregates {
    margin: 0;
    font-weight: 500;
  }
  .and-gas-free {
    font-weight: 500;
  }
  .learn-more {
    font-family: "DM Sans";
    color:rgb(64, 252, 254) ;
    font-weight: 600;
  }
  .and-gas-free-swaps-learn-more {
    margin: 0;
  }
  .when-available-aggregates-container {
    position: relative;
    font-size: 12px;
    text-align: left;
  }
  .trendswapx-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .toggle-button-child {
    width: 20px;
    position: relative;
    border-radius: 50%;
    background-color: #131313;
    height: 20px;
  }
  .toggle-button {
    width: 52.33px;
    border-radius: 99px;
    background-color: #353535;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px;
    box-sizing: border-box;
  }
  .active .toggle-button-child {
      transform: translateX(20px); /* Move button to the right */
    }
  .setting-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
  }
  .max-slippage {
    position: relative;
    font-weight: 500;
  }
  .octiconquestion-24 {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .max-slippage-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
  }
  .auto {
    position: relative;
  }
  .component-3-icon {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .auto-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    color: #f1f1f1;
  }
  .frame-container {
    align-self: stretch;
    border-top: 0.7px solid #9b9797;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
  }
  .frame-group {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 12px;
  }
  .setting {
    position: absolute;
    border-radius: 16px;
    background-color: #131313;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    box-sizing: border-box;
    gap: 16px;
    text-align: center;
    font-size: 16px;
    color: #b5b5b5;
    font-family: "DM Sans";
    z-index: 9999;
    flex-wrap: wrap;
    left: 20%;
    top: 16%;
  }
 
  
  .gmxWyU {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 12px;
    /* min-height: 200px; */
  }
  
  .bIFEzi {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  
  .eVSMfe {
    /* width: 100%; */
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
  
    gap: 12px;
  }
  
  .dKubqp {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
  }
  
  .gTwleA {
    width: auto;
    padding: 4px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 16px;
  
  }
  
  .cPCYrp {
    display: flex;
  
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  
  .dznSOZ {
      width: auto;
      cursor: pointer;
      padding: 6px 12px;
      text-align: center;
      gap: 4px;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.07);
      pointer-events: none;
  }
  .fCURlt {
      width: auto;
      cursor: pointer;
      padding: 6px 12px;
      text-align: center;
      gap: 4px;
      border-radius: 12px;
      background: transparent;
  }
  .css-1urox24 {
      box-sizing: border-box;
      margin: 0px;
      min-width: 0px;
      font-weight: 485;
      font-size: 16px;
  }
  
  .jwRPKK {
      color: rgb(255, 255, 255);
      letter-spacing: -0.01em;
  }
  
  .bkPaeK {
      padding: 8px 4px;
      border-radius: 12px;
      width: 100px;
      flex: 1 1 0%;
      border: 1px solid rgba(255, 255, 255, 0.07);
  }
  .bkPaeK input {
      color: rgb(255, 255, 255);
  }
  
  
  .eupJID {
      width: 100%;
      display: flex;
      flex: 1 1 0%;
      font-size: 16px;
      border: 0px;
      outline: none;
      background: transparent;
      text-align: right;
  }
  .red-color{
    color: red;
  }

  .css-142zc9n{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 0px;
    min-width: 0px;
    font-weight: 485;
    font-size: 14px;
}
.hMbAQl {
    color: rgb(238, 179, 23);
    letter-spacing: -0.01em;
}
